import React from 'react';
import Slider from "./Slider";

const Header = () => {
    return (
        <header className="header-bg h-screen" id="home">
            <div className="relative h-full flex items-center">

                <div id="header-content" className="p-8 mx-auto">
                    <div className="w-full mx-auto">
                        <Slider/>
                    </div>
                </div>
                {/*<Services/>*/}
            </div>
        </header>
    );
};

export default Header;