import React from 'react';
import normalisation from "../images/about/normalisation.svg"
import inteeligence from "../images/about/intéligence.svg"
import Optimisation from "../images/about/Optimisation.svg"
import strategie from "../images/about/stratégie.svg"

const About = () => {

    return (
        <div className="w-full container mx-auto my-16 leading-loose px-2 lg:px-4" id="about">
            <h1 className="font-bold text-center text-4xl title">Qui sommes-nous?…</h1>
            <div className="text-gray-600 text-xl pt-4 px-2">
                <p>
                    Développement des compétences, besoins des managers, planification des collaborateurs, stratégie de l’entreprise, adéquation des actions, évaluation du marché des fournisseurs et les exigences d’audit…. sont des contraintes que les ressources chargées de formation sont amenées à gérer au quotidien. C’est un système d’équations complexes qui fait notre raison d’être.
                </p>
                <p className="pt-4">
                    Bluescratch est une startup fruit de 15 ans d’expérience, d’une équipe polyvalente. C’est aussi un mixte entre des professionnels en formation et des experts dans la nouvelle technologie.
                </p>
            </div>
            <h1 className="text-center text-4xl mx-auto pt-16 title max-w-3xl">Bluescratch vous accompagne pendant toutes les étapes de votre formation</h1>
            {/*<div className="grid lg:grid-cols-4 gap-4 lg:gap-8">*/}
            <div className="lg:flex w-fit mx-auto pt-10">
                <div className="flex-col items-center w-64 p-2">
                    <img src={Optimisation} alt="icond" className="w-32 h-32 mx-auto"/>
                    <h2 className="text-center font-bold text-blue text-xl pt-4" id="optimisation">Optimisation</h2>
                    <p className="text-center text-gray-500 pt-2">Optimiser et smartiser votre ingénierie de formation.</p>
                </div>
                <div className="flex-col items-center w-64 p-2 lg:ml-4">
                    <img src={strategie} alt="icond" className="w-32 h-32 mx-auto"/>
                    <h2 className="text-center font-bold text-blue text-xl pt-4" id="strategie">Stratégie</h2>
                    <p className="text-center text-gray-500 pt-2">Aligner l’activité formation avec le gap en compétences et la stratégie de votre entreprise.</p>
                </div>
                <div className="flex-col items-center w-64 p-2 lg:ml-4">
                    <img src={normalisation} alt="icond" className="w-32 h-32 mx-auto"/>
                    <h2 className="text-center font-bold text-blue text-xl pt-4" id="normalisation">Normalisation</h2>
                    <p className="text-center text-gray-500 pt-2">Nouvelles approches bien maitrisées selon la norme ISO 29990.</p>
                </div>
                <div className="flex-col items-center w-64 p-2 lg:ml-4">
                    <img src={inteeligence} alt="icond" className="w-32 h-32 mx-auto"/>
                    <h2 className="text-center font-bold text-blue text-xl pt-4" id="intelligence">Intelligence</h2>
                    <p className="text-center text-gray-500 pt-2">Modules intelligents cloud based pour une transformation digitale de l’activité formation.</p>
                </div>
            </div>
        </div>
    );
};

export default About;