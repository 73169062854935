import React from 'react';
import logo_1 from "../images/clients/logo_1.jpg"
import logo_2 from "../images/clients/logo_2.jpg"
import logo_3 from "../images/clients/logo_3.jpg"
import logo_4 from "../images/clients/logo_4.jpg"

import {ArrowRightAlt} from "@mui/icons-material";
import Contact from "./Contact";

const Forms = () => {

    return (
        <div className="w-full container mx-auto py-16 leading-loose px-2 lg:px-4" id="forms">
            <h1 className="font-bold text-center text-4xl title pt-10">Nos formules</h1>
            <div className="container px-2 lg:px-4 w-fit mx-auto">
                <div className="w-full lg:flex pt-8 mx-auto">
                    <div className="flex-1 border rounded-xl shadow-lg p-4 max-w-3xl">
                        <hr className="text-blue-500"/>
                        <h1 className="text-blue-500 font-small">Formule 1</h1>
                        <h2 className="text-blue pt-8">Abonnement plateforme</h2>
                        <ul className=" text-gray-500 pt-6">
                            <li><ArrowRightAlt className="text-blue-500"/> Transfert de compétence.</li>
                            <li><ArrowRightAlt className="text-blue-500"/> Accès au différents profils.</li>
                            <li><ArrowRightAlt className="text-blue-500"/> Accompagnement et maintenance.</li>
                        </ul>
                    </div>
                    <div className="flex-1 border rounded-xl shadow-lg p-4 max-w-3xl lg:ml-16 mt-8 lg:mt-0">
                        <hr className="text-blue-500"/>
                        <h1 className="text-blue-500 font-small">Formule 2</h1>
                        <h2 className="text-blue pt-8">Sous-traitance</h2>
                        <div className="text-gray-500 pt-4 px-2">
                            <p>Prendre en charge l’ensemble de l’activité « formation et développement des compétences », et donner accès aux tableaux de bord pour le suivi des objectifs d’apprentissage</p>
                        </div>
                        <ul className=" text-gray-500 pt-6">
                            <li><ArrowRightAlt className="text-blue-500"/> Accès apprenant.</li>
                            <li><ArrowRightAlt className="text-blue-500"/> Accès manager.</li>
                            <li><ArrowRightAlt className="text-blue-500"/> Accès super-manager.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container px-2 lg:px-4 w-fit mx-auto pt-16" id="clients">
                <h1 className="font-bold text-center text-4xl pt-10 title">Nos clients</h1>
                <div className="lg:flex pt-8">
                    <img src={logo_1} alt="client" className="h-28"/>
                    <img src={logo_2} alt="client" className="h-28"/>
                    <img src={logo_3} alt="client" className="h-28"/>
                    <img src={logo_4} alt="client" className="h-28"/>
                </div>
            </div>
            <div className="px-2 lg:px-4 w-fit mx-auto pt-16" id="demo">
                <h1 className="font-bold text-center text-4xl title max-w-2xl pt-10 mx-auto">Demandez une démonstration gratuite de Bluescratch</h1>
                <Contact/>
            </div>
        </div>
    );
};

export default Forms;