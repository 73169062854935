import React from 'react';
import facebook from "../images/media-icons/facebook.svg"
import twitter from "../images/media-icons/twitter.svg"
import linkedin from "../images/media-icons/linkedin.svg"
import {AddLocation, Email, Smartphone} from "@mui/icons-material";
const Footer = () => {

    return (
        <footer  id="footer">
            <div className="w-full container mx-auto py-16 leading-loose px-2 lg:px-4">
                <div className="grid lg:grid-cols-3 gap-4">
                    <div className="flex-col">
                        <h1 className="font-bold text-2xl text-yellow-400">Bluescratch</h1>
                        <p className="text-gray-300 mt-4">Votre ingénierie de formation sur le cloud</p>
                        <div className="flex mt-4">
                            <a href="#" className=""><img src={facebook} alt="Bluescratch page Facebook"/></a>
                            <a href="#" className="ml-2"><img src={twitter} alt="Bluescratch on Twitter"/></a>
                            <a href="#" className="ml-2"><img src={linkedin} alt="Bluescratch on Linkedin"/></a>
                        </div>
                    </div>
                    <div className="flex-col text-white text-xl">
                        <ul>
                            <li><a href="#">Accueil</a></li>
                            <li><a href="#">Valeurs</a></li>
                            <li><a href="#">Solution</a></li>
                            <li><a href="#">Formules</a></li>
                            <li><a href="#">Références</a></li>
                        </ul>
                    </div>
                    <div className="flex-col text-white text-xl">
                        <ul>
                            <li><div className="flex"><AddLocation className="contact-icon text-yellow-400 mr-2 "/><p>119 Bd Abdelmoumen <br/>Etg 3, Porte 20 <br/> Casablanca</p></div></li>
                            <li><div className="flex"><Smartphone className="contact-icon text-yellow-400 mr-2 "/><p>(+212) 661 057 736 <br/>(+212) 615 452 524</p></div></li>
                            <a href="mailto:contact@blue-scratch.com"><li><Email className="contact-icon text-yellow-400 mr-2 "/> contact@blue-scratch.com</li></a>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;