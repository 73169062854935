import React from 'react';
import modules1 from "../images/solution/bluescratch-modules--300x300.png"
import modules2 from "../images/solution/bluescratch-modules-2-300x300.png"
import modules3 from "../images/solution/bluescratch-modules-3-300x300.png"
import modules4 from "../images/solution/bluescratch-modules-4-300x300.png"
import modules5 from "../images/solution/bluescratch-modules-5-300x300.png"
import {CheckCircle} from "@mui/icons-material";

const Solution = () => {

    return (
        <div className="w-full mx-auto py-16 leading-loose  px-2 lg:px-4" id="solution">
            <h1 className="text-center text-4xl title pt-8">Adoptez Bluescratch</h1>
            <div className="container px-2 lg:px-4 mx-auto  ">
                <div className="text-gray-500 text-lg pt-4 px-2">
                    <p>La solution BS est une plateforme cloud, modulaire digitalisant tous les aspects de la formation professionnelle.</p>
                </div>
                <div className="pt-16 grid lg:grid-cols-3 gap-4 lg:gap-8">
                    <div className="flex-col">
                        <h2 className="text-lg font-medium"><CheckCircle className="check-icon"/> ISO 29990</h2>
                        <p className="text-gray-500 leading-normal pt-4">Une ingénierie de formation digitalisée assurant les normes 29990.</p>
                    </div>
                    <div className="flex-col">
                        <h2 className="text-lg font-medium"><CheckCircle className="check-icon"/> Adaptive Learning</h2>
                        <p className="text-gray-500 leading-normal pt-4">Un apprentissage individualisé « Adaptive Learning ».</p>
                    </div>
                    <div className="flex-col">
                        <h2 className="text-lg font-medium"><CheckCircle className="check-icon"/> Objectifs</h2>
                        <p className="text-gray-500 leading-normal pt-4">Suivi des objectifs d’apprentissage.</p>
                    </div>
                    <div className="flex-col">
                        <h2 className="text-lg font-medium"><CheckCircle className="check-icon"/> Worflow</h2>
                        <p className="text-gray-500 leading-normal pt-4">Gestion des workflow formation.</p>
                    </div>
                    <div className="flex-col">
                        <h2 className="text-lg font-medium"><CheckCircle className="check-icon"/> Personalisation</h2>
                        <p className="text-gray-500 leading-normal pt-4">Des parcours de formation personnalisée selon le secteur.</p>
                    </div>
                    <div className="flex-col">
                        <h2 className="text-lg font-medium"><CheckCircle className="check-icon"/> Virtualisation</h2>
                        <p className="text-gray-500 leading-normal pt-4">Gestion de la virtualisation de formation.</p>
                    </div>
                </div>
            </div>
            <h1 className="text-center text-4xl mx-auto pt-16 title">Réservez votre démo gratuite avec un consultant</h1>
            <div className="pt-16 w-fit mx-auto justify-center">
                <button className="mx-auto font-medium text-xl px-4 tracking-wide">Demander une demonstration</button>
            </div>
            <h1 className="text-center text-4xl mx-auto pt-16 title max-w-3xl">Les modules</h1>
            <div className="lg:flex w-fit mx-auto pt-10">
                <img src={modules1} alt="icond" className="w-60 h-60 mx-auto"/>
                <img src={modules2} alt="icond" className="w-60 h-60 mx-auto"/>
                <img src={modules3} alt="icond" className="w-60 h-60 mx-auto"/>
                <img src={modules4} alt="icond" className="w-60 h-60 mx-auto"/>
                <img src={modules5} alt="icond" className="w-60 h-60 mx-auto"/>

            </div>
        </div>
    );
};

export default Solution;