import React from 'react';
import {Menu} from "@mui/icons-material";
import logo from "../../images/logo.png"

const Navbar = () => {


    function toggle() {
        const hamburger = document.querySelector(".hamburger");
        const navLinks = document.querySelector(".nav-links");
        const links = document.querySelectorAll(".nav-links li");
        //Animate Links
        navLinks.classList.toggle("open");
        links.forEach(link => {
            link.classList.toggle("fade");
        });

        //Hamburger Animation
        hamburger.classList.toggle("toggle");
    }

    return (
        <div>
            <nav className="flex fixed w-full">
                <div className="flex w-full lg:px-4 ">
                    <div className="logo flex items-center">
                        <img className="h-16" src={logo} alt="Bluescratch"/>
                    </div>
                    <div className="hamburger">
                        <Menu onClick={toggle}/>
                    </div>
                    <div className="flex justify-end w-full text-gray-500">
                        <ul className="nav-links">
                            <li><a href="#home">Accueil</a></li>
                            <li><a href="#values">Valeurs</a></li>
                            <li><a href="#solution">Solution</a></li>
                            <li><a href="#forms">Formules</a></li>
                            <li><a href="#clients">Références</a></li>
                            <li><a href="#map">Contact</a></li>
                            <li className="show-login-btn"><a href="#demo"><button className="join-button">Demander une démo</button></a></li>
                        </ul>
                        <ul className="nav-links ">
                            <li className="ml-2"><a href="#demo"><button className="join-button">Demander une démo</button></a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;