import React from 'react';
import missions from "../images/missions.png"
import {ArrowRightAlt} from "@mui/icons-material";
const Missions = () => {
    return (
        <div className="w-full" id="mission">
            <div className="w-full mx-auto">
                <div className="pt-16 flex items-center">
                    <div className="lg:flex py-8 leading-loose  mx-auto">
                        <div className="px-4">
                            <img id="about-pic" src={missions} alt="Bluescratch missions" className="flex lg:max-w-2xl"/>
                        </div>
                        <div id="profiledescription" className="">
                            <h1 className="font-bold text-center text-4xl title">Mission</h1>
                            <p className="p-4 text-left max-w-2xl text-gray-500">Amélioration de l’écosystème d’apprentissage, et rendre l’information et la formation plus disponible et démocratisé.</p>
                            <p className="p-4 text-left max-w-2xl text-gray-500 pt-4">
                                Pour ce, Bluescratch se fixe des objectifs afin de contribuer régulièrement à la technologie de l’éducation en se basant sur des idées nouvelles et créatives, à savoir:
                            </p>
                            <ul className="px-4 text-left max-w-2xl text-gray-500">
                                <li><ArrowRightAlt className="text-blue-500"/> Une mise à jour de ses systèmes d’Information pédagogiques.</li>
                                <li><ArrowRightAlt className="text-blue-500"/> Des publications scientifiques.</li>
                                <li><ArrowRightAlt className="text-blue-500"/> Des séminaires et formations.</li>
                            </ul>

                            <h1 className="font-bold text-center text-4xl title pt-8">Vision</h1>
                            <ul className="px-4 text-left max-w-2xl text-gray-500">
                                <li><ArrowRightAlt className="text-blue-500"/> Aligner l’activité formation avec le développement des compétences.</li>
                                <li><ArrowRightAlt className="text-blue-500"/> Concevoir des plan de formation individualisé et intégrer la notion de l’apprentissage adaptatif.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Missions;