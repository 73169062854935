import React from 'react';

const Map = () => {
    return (
        <div id="map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6648.152149420819!2d-7.625377!3d33.577374!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d2b11a0f0e2b%3A0x44e19d95e92fb0bf!2s119%20Bd%20Abdelmoumen%2C%20Casablanca%2020250!5e0!3m2!1sfr!2sma!4v1650755192823!5m2!1sfr!2sma"
                width="100%" height="450" allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    );
};

export default Map;