import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SSlider from "react-slick";
import s1 from "../../images/slides/s1.png";
import s2 from "../../images/slides/s2.png";
import s3 from "../../images/slides/s3.png";

const Slider = () => {
    return (
        <div id="slider">

            <div className="">
                <SSlider
                    dots={false}
                    infinite={true}
                    speed={3000}
                    slidesToShow={1}
                    slidesToScroll={1}
                    autoplay={true}
                    autoplaySpeed={2500}
                >
                    <div id="web-slid">
                        <div className="lg:flex items-center">
                            <div className="flex-1 max-w-xl lg:px-8">
                                <p className="font-bold text-white lg:text-2xl ">
                                    La solution optimale pour collecter, valider, planifier et suivre vos actions de formation.
                                </p>
                                <a href="#demo"><button className="login-button mt-8">Planifier votre démo</button></a>
                            </div>
                            <div className="flex-1 lg:ml-8 w-full">
                                <img src={s1} alt="ty" className="lg:ml-auto"/>
                            </div>
                        </div>
                    </div>
                    <div id="web-slid">
                        <div className="lg:flex items-center">
                            <div className="flex-1 max-w-xl lg:px-8">
                                <p className="font-bold text-white lg:text-2xl ">
                                    Votre plan de formation est simplement généré grâce à l’ingénierie de formation digitalisée.
                                </p>
                                <a href="#demo"><button className="login-button mt-8">Planifier votre démo</button></a>
                            </div>
                            <div className="flex-1 lg:ml-8 w-full">
                                <img src={s2} alt="ty" className="lg:ml-auto"/>
                            </div>
                        </div>
                    </div>
                    <div id="web-slid">
                        <div className="lg:flex items-center">
                            <div className="flex-1 max-w-xl lg:px-8">
                                <p className="font-bold text-white lg:text-2xl ">
                                    Accompagnement ou soustraitance, Bluescratch propose des « smart dashboard » pour suivre la météo de votre activité formation.
                                </p>
                                <a href="#demo"><button className="login-button mt-8">Planifier votre démo</button></a>
                            </div>
                            <div className="flex-1 lg:ml-8 w-full">
                                <img src={s3} alt="ty" className="lg:ml-auto"/>
                            </div>
                        </div>
                    </div>
                </SSlider>
            </div>
        </div>
    );
};

export default Slider;