import React from 'react';
import engagement from "../images/values/V-engagement.svg";
import creativitee from "../images/values/V-creativitee.svg";
import integrite from "../images/values/V-integrite-.svg";
import respetc from "../images/values/V-respetc.svg";

const Values = () => {
    return (
        <div className="w-full container mx-auto py-16 leading-loose" id="values">
            <h1 className="text-center text-4xl mx-auto pt-16 title max-w-3xl">Valeurs</h1>
            {/*<div className="grid lg:grid-cols-4 gap-4 lg:gap-8">*/}
            <div className="lg:flex w-fit mx-auto pt-10">
                <div className="flex-col items-center w-64 p-2 mt-3">
                    <img src={integrite} alt="icond" className="w-32 h-32 mx-auto"/>
                    <h2 className="text-center font-bold text-blue text-xl pt-2" id="integrite">Intégrité</h2>
                </div>
                <div className="flex-col items-center w-64 p-2 mt-3 lg:ml-4">
                    <img src={engagement} alt="icond" className="w-32 h-32 mx-auto"/>
                    <h2 className="text-center font-bold text-blue text-xl pt-2" id="engagement">Engagement</h2>
                </div>
                <div className="flex-col items-center w-64 p-2 mt-3 lg:ml-4">
                    <img src={creativitee} alt="icond" className="w-32 h-32 mx-auto"/>
                    <h2 className="text-center font-bold text-blue text-xl pt-2" id="creativitee">Créativité</h2>
                </div>
                <div className="flex-col items-center w-64 p-2 mt-3 lg:ml-4">
                    <img src={respetc} alt="icond" className="w-32 h-32 mx-auto"/>
                    <h2 className="text-center font-bold text-blue text-xl pt-2" id="respetct">Respect</h2>
                </div>
            </div>
        </div>
    );
};

export default Values;