import React, {useState} from 'react';
import formulaire from "../images/formulaire.png";
import {TextField} from "@mui/material";
import {ReCAPTCHA} from "react-google-recaptcha";
import axios from 'axios';
const Contact = () => {
    const [fname, setFName] = useState("");
    const [lname, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [socity, setSocity] = useState("");
    const [fonction, setFonction] = useState("");
    const [sector, setSector] = useState("");
    const [message, setMessage] = useState("");
    const [sended, setSended] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            fname,
            lname,
            email,
            phone,
            socity,
            fonction,
            sector,
            msg: message,
        };

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': "Bearer ij",
        }

        axios.post(`https://iglomi.com/api/bluescratch/sendemail`, { ...data } ,{headers})
            .then(res => {
                setSended(true)
            })
    }
    function handlerecaptcha(value) {
        console.log("Captcha value:", value);
    }

    return (
        <div className="w-full mx-auto">
            <div className="pt-16 flex items-center">
                <div className="lg:flex py-8">
                    <div className="px-4">
                        <img id="about-pic" src={formulaire} alt="Rachid hamma ali" className="lg:max-w-xl"/>
                    </div>
                    <div id="profiledescription" className="flex items-center">
                        {!sended ? <div className="w-full grid lg:grid-cols-2 gap-4 lg:gap-8">

                            <TextField
                                label="* Prénom"
                                variant="filled"
                                value={lname}
                                onChange={(e) => {
                                    setLName(e.target.value);
                                }}
                            />
                            <TextField
                                label="* Nom"
                                variant="filled"
                                value={fname}
                                onChange={(e) => {
                                    setFName(e.target.value);
                                }}
                            />
                            <TextField
                                label="* Email"
                                variant="filled"
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                            <TextField
                                label="* Téléphone"
                                variant="filled"
                                value={phone}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                }}
                            />
                            <TextField
                                className="lg:col-span-2"
                                label="* Société"
                                variant="filled"
                                value={socity}
                                onChange={(e) => {
                                    setSocity(e.target.value);
                                }}
                            />
                            <TextField
                                label="* Fonction"
                                variant="filled"
                                value={fonction}
                                onChange={(e) => {
                                    setFonction(e.target.value);
                                }}
                            />
                            <TextField
                                label="* Secteur d'activité"
                                variant="filled"
                                value={sector}
                                onChange={(e) => {
                                    setSector(e.target.value);
                                }}
                            />
                            <TextField
                                className="lg:col-span-2"
                                multiline={true}
                                rows={5}
                                label="* Message"
                                variant="filled"
                                value={message}
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                }}
                            />
                            <div>
                                <ReCAPTCHA
                                    sitekey="6LfKxZYfAAAAABXmPT5iWBH3AYyfNqxWX0gWDmMo"
                                    onChange={handlerecaptcha}
                                />
                            </div>
                            <div className="w-full flex justify-end">
                                <button className="submit-btn w-32"
                                onClick={handleSubmit}
                                >Envoyer</button>
                            </div>
                        </div>
                            :
                            <div>
                                <p className="font-bold text-2xl text-green-500">VOTRE MESSAGE A ÉTÉ ENVOYÉ AVEC SUCCÈS.</p>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;